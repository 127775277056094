import React, { useState, useEffect, useContext, useCallback } from 'react';
import { navigate, PageProps } from 'gatsby';
import { useMediaQuery } from '@material-ui/core';
import { SEO } from '@components';
import {
  LayoutMissionDesktop,
  ChooseInvestorProfile,
  LayoutMissionMobile,
} from '@components';
import { useAuth, useForm } from '@hooks';
import { AppContext } from '@context';
//Important to update mission
import { updateUser, completeMissionMutation, getUserMissions } from '@apollo';
import { useApolloClient } from '@apollo/client';
import { MissionsInfo } from '@data';
import { nextMissionModalInfo } from '@utils';
import { Route } from '@interfaces';

const M4InvestorProfile: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const isMobile = useMediaQuery('(min-width: 1240px)');
  const { openCongratsModal } = useContext(AppContext);
  const [form] = useForm({});
  const [title, setTitle] = useState<string>('Elige tu perfil de inversión');
  const [subtitle, setSubtitle] = useState<string>(
    'Selecciona el perfil que más te represente'
  );
  const [confettiFire, setConfettiFire] = useState<boolean>(false);

  const { userMissions } = getUserMissions(); //!! to update mission
  const client = useApolloClient(); //!! to update mission

  const categoryMissions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.ProfileCategory.name
  );
  const currentMission = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.ProfileCategory.m4.id
  )[0];

  const handleClick = () => {
    // if (currentMission?.state == "PENDING") {
    try {
      updateUser(form, true, client);
      completeMissionMutation(client, currentMission.mission.id);
      setConfettiFire(true);
      openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    } catch (error) {
      console.log(error);
    }
    // } else {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // }
  };

  useEffect(() => {
    // if (currentMission?.state == "COMPLETED") {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // } else {
    //   setConfettiFire(false);
    // }
    setConfettiFire(false);
  }, [currentMission]);

  const isDiscoverOn = () => {
    setTitle('Cuéntanos un poco de ti');
    setSubtitle(
      'Así podrás descubrir inversiones que se ajusten a tus objetivos'
    );
  };
  const onHouseClick = useCallback(() => {
    navigate(Route.completeProfile);
  }, []);
  return (
    <>
      <SEO />
      {isMobile ? (
        <LayoutMissionDesktop
          title={title}
          subtitle={subtitle}
          number={3}
          confettiFire={confettiFire}
          onHouseClick={onHouseClick}
        >
          <ChooseInvestorProfile
            form={form}
            buttonText={'Teminar misión'}
            handleClick={handleClick}
            isDiscoverOn={isDiscoverOn}
          />
        </LayoutMissionDesktop>
      ) : (
        <LayoutMissionMobile
          title={title}
          subtitle={subtitle}
          number={4}
          onHouseClick={() => navigate(Route.completeProfile)}
        >
          <ChooseInvestorProfile
            form={form}
            buttonText={'Teminar misión'}
            handleClick={handleClick}
            isDiscoverOn={isDiscoverOn}
          />
        </LayoutMissionMobile>
      )}
    </>
  );
};

export default M4InvestorProfile;
